/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Peter's Portfolio",
  description:
    "A passionate Software Engineer and Graphic Designer dedicated to working on end-to-end products that develop sustainable and scalable social and technical systems to create meaningful impact.",
  og: {
    title: "Peter's Portfolio",
    type: "website",
    url: "http://peter-kibet.com/",
  },
};

//Home Page
const greeting = {
  title: "Peter🕸️Kibet",
  logo_name: "Peter",
  nickname: "spidey",
  subTitle:
    "A passionate Software Engineer and Graphic Designer dedicated to working on end-to-end products that develop sustainable and scalable social and technical systems to create meaningful impact.",
  resumeLink:
    "https://docs.google.com/document/d/1NIJmHwj6KTvTC9N_5BUy-HQyiT_M8gj6/edit?usp=drive_link&ouid=117793958848832542855&rtpof=true&sd=true",
  portfolio_repository:
    "https://docs.google.com/document/d/1NIJmHwj6KTvTC9N_5BUy-HQyiT_M8gj6/edit?usp=drive_link&ouid=117793958848832542855&rtpof=true&sd=true",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/Peter-Kibet",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/spidey/"

  {
    name: "Github",
    link: "https://github.com/Peter-Kibet",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "Behance",
    link: "https://www.behance.net/peterkibet2",
    fontAwesomeIcon: "fa-behance", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/Peter-Kibet/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:peterkibet@spideyinc.com",
    fontAwesomeIcon: "fa fa-envelope", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "X-Twitter",
    link: "https://x.com/Peter_Montana_J",
    fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/spideyincorporation/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/spidey_inc/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Web Development",
      fileName: "FullStackImg",
      skills: [
        "🕷️ Building responsive front-end web applications using React-Redux and NextJs",
        "🕷️ Developing mobile applications with Flutter, React Native, and Kotlin",
        "🕷️ Creating back-end applications with Node.js, Express, and Flask",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Gatsby",
          fontAwesomeClassname: "simple-icons:gatsby",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    // {
    //   title: "Data Science & AI",
    //   fileName: "DataScienceImg",
    //   skills: [
    //     "🕷️ Developing highly scalable production ready models for various deeplearning and statistical use cases",
    //     "🕷️ Experience of working with Computer Vision and NLP projects",
    //     "🕷️ Complex quantitative modelling for dynamic forecasting and time series analysis",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "Tensorflow",
    //       fontAwesomeClassname: "logos-tensorflow",
    //       style: {
    //         backgroundColor: "transparent",
    //       },
    //     },
    //     {
    //       skillName: "Keras",
    //       fontAwesomeClassname: "simple-icons:keras",
    //       style: {
    //         backgroundColor: "white",
    //         color: "#D00000",
    //       },
    //     },
    //     {
    //       skillName: "PyTorch",
    //       fontAwesomeClassname: "logos-pytorch",
    //       style: {
    //         backgroundColor: "transparent",
    //       },
    //     },
    //     {
    //       skillName: "Python",
    //       fontAwesomeClassname: "ion-logo-python",
    //       style: {
    //         backgroundColor: "transparent",
    //         color: "#3776AB",
    //       },
    //     },
    //     {
    //       skillName: "Deeplearning",
    //       imageSrc: "deeplearning_ai_logo.png",
    //     },
    //   ],
    // },
    {
      title: "Cloud Infrastructure & Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "🕷️ Expertise with various cloud platforms",
        "🕷️ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "🕷️ Deploying deep learning models on the cloud for mobile use",
        "🕷️ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DataScienceImg",
      skills: [
        "🕷️ Designing highly attractive user interface for mobile and web applications",
        "🕷️ Customizing logo designs and building logos from scratch",
        "🕷️ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#000000",
          },
        },
      ],
    },
    {
      title: "Graphic Design",
      fileName: "DesignImg",
      skills: [
        "🕷️ Creating visually appealing designs for branding and marketing materials",
        "🕷️ Proficient in Adobe Creative Suite, including Photoshop, Illustrator, After Effects, and Premiere Pro",
        "🕷️ Experienced in using Figma for UI/UX design and creating user-friendly interfaces",
        "🕷️ Skilled in Behance for showcasing creative work",
        "🕷️ Competent in using WonderShare Filmora, After Effects and Premiere Pro for video editing and post-production",
      ],
      softwareSkills: [
        {
          skillName: "Photoshop",
          fontAwesomeClassname: "simple-icons:adobephotoshop",
          style: {
            backgroundColor: "transparent",
            color: "#31A8FF",
          },
        },
        {
          skillName: "Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            backgroundColor: "transparent",
            color: "#FF9A00",
          },
        },
        {
          skillName: "After Effects",
          fontAwesomeClassname: "simple-icons:adobeaftereffects",
          style: {
            backgroundColor: "transparent",
            color: "#9999FF",
          },
        },
        {
          skillName: "Adobe Premiere Pro",
          fontAwesomeClassname: "simple-icons:adobepremierepro",
          style: {
            backgroundColor: "transparent",
            color: "#9999FF",
          },
        },
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            backgroundColor: "transparent",
            color: "#FF61F6",
          },
        },
        {
          skillName: "Behance",
          fontAwesomeClassname: "fa-behance",
          style: {
            backgroundColor: "transparent",
            color: "#1769FF",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            backgroundColor: "transparent",
            color: "#F24E1E",
          },
        },
        {
          skillName: "WonderShare Filmora",
          fontAwesomeClassname: "simple-icons:wondershare",
          style: {
            backgroundColor: "transparent",
            color: "#16A6EF",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/Peter-Kibet/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/peter_kibet",
    },
    // {
    //   siteName: "Codechef",
    //   iconifyClassname: "simple-icons:codechef",
    //   style: {
    //     color: "#5B4638",
    //   },
    //   profileLink: "https://www.codechef.com/users/ashutosh_1919",
    // },
    // {
    //   siteName: "Codeforces",
    //   iconifyClassname: "simple-icons:codeforces",
    //   style: {
    //     color: "#1F8ACB",
    //   },
    //   profileLink: "http://codeforces.com/profile/spidey",
    // },
    // {
    //   siteName: "Hackerearth",
    //   iconifyClassname: "simple-icons:hackerearth",
    //   style: {
    //     color: "#323754",
    //   },
    //   profileLink: "https://www.hackerearth.com/@ashutosh391",
    // },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/peterkibetspidey",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Technical University of Mombasa",
      subtitle: "B.Science. in Information Technology",
      logo_path: "TUM.png",
      alt_name: "IIITDM Kurnool",
      duration: "2019 - 2024",
      descriptions: [
        "🕷️ Acquired comprehensive knowledge in fundamental software engineering subjects, including Data Structures, Algorithms, Database Management Systems, Operating Systems, Computer Architecture, and Artificial Intelligence.",
        "🕷️ Completed advanced coursework in Deep Learning, Data Science, Cloud Computing, and Full Stack Development.",
        "🕷️ Successfully executed multiple projects applying theoretical knowledge to practical scenarios, showcasing a strong understanding of the material.",
      ],
      website_link: "http://tum.ac.ke",
    },
    // {
    //   title: "Indiana University Bloomington",
    //   subtitle: "M.S. in Computer Science",
    //   logo_path: "iu_logo.png",
    //   alt_name: "Indiana University Bloomington",
    //   duration: "2021 - 2023",
    //   descriptions: [
    //     "🕷️ I have taken varity of courses related to Artificial Intelligence which correspond to Explainable AI, Graph Machine Learning, Computer Vision etc.",
    //     "🕷️ Apart from this, I have also done research assistantship. As part of it, I have worked on creating new algorithms in Graph ML and Network Science.",
    //     "🕷️ During my time at university, I was also associated with multimedia department. As part of it, I have worked on some documentry films and interviews.",
    //   ],
    //   website_link: "https://www.indiana.edu/",
    // },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Full Stack Web Development",
      subtitle: "- Microverse",
      logo_path: "microverse small.png",
      certificate_link:
        "https://drive.google.com/file/d/1Id_9hAB_JdPJ8mPAUFPLZB93_TTydMCH/view?usp=sharing",
      color_code: "#6f36ff",
    },
    {
      title: "Software Engineering",
      subtitle: "- Moringa School Kenya",
      logo_path: "moringa2.png",
      certificate_link:
        "https://drive.google.com/file/d/1Dtzd3Lw1xfK-0c5_FoSZDuDIsqs05OZt/view?usp=sharing",
      alt_name: "Software Engineering - Moringa School Kenya",
      color_code: "#ffffff",
    },
    {
      title: "AI Career Essentials",
      subtitle: "- ALX Africa",
      logo_path: "ALX.svg",
      certificate_link: "https://intranet.alxswe.com/certificates/shY9PRzFEL",
      alt_name: "ALX Africa",
      color_code: "#00acd4",
    },
    {
      title: "Web Development Essentials",
      subtitle: "- Free Code Camp",
      logo_path: "free-code-camp.svg",
      certificate_link:
        "https://www.freecodecamp.org/certification/Spidey_Acer/responsive-web-design",
      alt_name: "Free Code Camp",
      color_code: "#ffffff",
    },
    // {
    //   title: "Big Data",
    //   subtitle: "- Kim Akers",
    //   logo_path: "microsoft_logo.png",
    //   certificate_link:
    //     "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
    //   alt_name: "Microsoft",
    //   color_code: "#D83B0199",
    // },
    // {
    //   title: "Advanced Data Science",
    //   subtitle: "- Romeo Kienzler",
    //   logo_path: "ibm_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",
    //   alt_name: "IBM",
    //   color_code: "#1F70C199",
    // },
    // {
    //   title: "Advanced ML on GCP",
    //   subtitle: "- GCP Training",
    //   logo_path: "google_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
    //   alt_name: "Google",
    //   color_code: "#0C9D5899",
    // },
    // {
    //   title: "DL on Tensorflow",
    //   subtitle: "- Laurence Moroney",
    //   logo_path: "deeplearning_ai_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
    //   alt_name: "deeplearning.ai",
    //   color_code: "#00000099",
    // },
    // {
    //   title: "Fullstack Development",
    //   subtitle: "- Jogesh Muppala",
    //   logo_path: "coursera_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
    //   alt_name: "Coursera",
    //   color_code: "#2A73CC",
    // },
    // {
    //   title: "Kuberenetes on GCP",
    //   subtitle: "- Qwiklabs",
    //   logo_path: "gcp_logo.png",
    //   certificate_link:
    //     "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
    //   alt_name: "GCP",
    //   color_code: "#4285F499",
    // },
    // {
    //   title: "Cryptography",
    //   subtitle: "- Saurabh Mukhopadhyay",
    //   logo_path: "nptel_logo.png",
    //   certificate_link:
    //     "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
    //   alt_name: "NPTEL",
    //   color_code: "#FFBB0099",
    // },
    // {
    //   title: "Cloud Architecture",
    //   subtitle: "- Qwiklabs",
    //   logo_path: "gcp_logo.png",
    //   certificate_link:
    //     "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
    //   alt_name: "GCP",
    //   color_code: "#4285F499",
    // },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with a variety of companies, from start-ups to large corporations, and have gained valuable experience in the fields of Software Development, Graphic Design Machine Learning, and Data Science. I have also volunteered with Google, Microsoft, and GitHub to help students learn about the latest technologies and tools.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Software Engineering Fellow",
          company: "Headstarter AI.",
          company_url: "https://www.headstarter.com/en/",
          logo_path: "theheadstarter_logo.jpeg",
          duration: "July 2024 - Present",
          location: "San Jose, CA, USA",
          description:
            "I am working on building 5 AI-powered projects within 7 weeks with a team of 3 developers. The projects include a social media platform, a chatbot, a recommendation system, a data visualization tool, and a machine learning model. I am responsible for developing the front-end and back-end of the projects, as well as deploying them to the cloud.",
          color: "#000000",
        },
        {
          title: "Full Stack Web Developer",
          company: "Upwork",
          company_url: "https://www.upwork.com/",
          logo_path: "upwork_logo.jpeg",
          duration: "June 2020 - Aug 2021",
          location: "Remote",
          description:
            "I worked as a full stack web developer on Upwork, a freelancing platform. I developed web applications for clients using React, Node.js, and MongoDB.",
          color: "#0879bf",
        },
        {
          title: "Freelance web Developer",
          company: "Self-Employed",
          company_url: "https://www.linkedin.com/in/peter-kibet",
          logo_path: "SpideyDpSVG.svg",
          duration: "Jan 2024 - Present",
          location: "Nairobi, Kenya",
          description:
            "Spearheaded the development of a comprehensive full-stack web application tailored for a leading client in the construction sector, aimed at optimizing their inventory management and sales operations. The solution, crafted using React for the frontend, Node.js for the backend, and MongoDB for the database, was delivered within the agreed timeline, exceeding client expectations. Additionally, conceptualized and executed a bespoke website for a renowned local chef, effectively showcasing his culinary expertise and expanding his digital footprint.",
          color: "#9b1578",
        },
        // {
        //   title: "Android Developer",
        //   company: "FreeCopy Pvt. Ltd.",
        //   company_url: "https://www.linkedin.com/company/freecopy/about/",
        //   logo_path: "freecopy_logo.png",
        //   duration: "Nov 2017 - Dec 2017",
        //   location: "Ahmedabad, Gujarat",
        //   description:
        //     "FreeCopy is the Start up from Indian Institute of Management, Ahmedabad. I have changed the integration of the whole app from Google to Firebase. I learnt the efﬁcient ways of Data communications like Retroﬁt, Eventbus etc. I experienced the real time start up. I learnt the Design thinking of UI on perspective of People.",
        //   color: "#fc1f20",
        // },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Student Apprentice",
          company: "Microverse.",
          company_url: "https://www.microverse.org/",
          logo_path: "microverseinc_logo.jpeg",
          duration: "Jul 2023 - Dec 2023",
          location: "Remote (San Francisco, USA)",
          description:
            "Engaged in an immersive, remote software development training program focusing on full-stack web development, including technologies such as Ruby on Rails, JavaScript, React, and Redux. Collaborated on numerous international projects, emphasizing agile methodologies, pair programming, and TDD. Developed a comprehensive portfolio of projects demonstrating proficiency in software development principles and practices. Actively participated in daily stand-ups, code reviews, and continuous learning sessions to enhance technical and soft skills, preparing for a successful career in software development.",
          color: "#000000",
        },
        // {
        //   title: "Data Science Research Intern",
        //   company: "Delhivery Pvt. Ltd.",
        //   company_url: "https://www.delhivery.com/",
        //   logo_path: "delhivery_logo.png",
        //   duration: "May 2019 - Sept 2019",
        //   location: "Gurgaon, Haryana",
        //   description:
        //     "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
        //   color: "#ee3c26",
        // },
        // {
        //   title: "Data Science Intern",
        //   company: "Intel Indexer LLC",
        //   company_url:
        //     "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
        //   logo_path: "intel_logo.jpg",
        //   duration: "Nov 2018 - Dec 2018",
        //   location: "Work From Home",
        //   description:
        //     "This is financial Solution Company. I have made Supervised Learning model for the company which can perform time series analysis on Stock price data for 32 companies. I have built LSTM Neural Networks Model and trained the data of 32 companies for last 2 years. This model is also used for forecasting.",
        //   color: "#0071C5",
        // },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        // {
        //   title: "Google Explore ML Facilitator",
        //   company: "Google",
        //   company_url: "https://about.google/",
        //   logo_path: "google_logo.png",
        //   duration: "June 2019 - April 2020",
        //   location: "Hyderabad, Telangana",
        //   description:
        //     "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
        //   color: "#4285F4",
        // },
        // {
        //   title: "Microsoft Student Partner",
        //   company: "Microsoft",
        //   company_url: "https://www.microsoft.com/",
        //   logo_path: "microsoft_logo.png",
        //   duration: "Aug 2019 - May 2020",
        //   location: "Hyderabad, Telangana",
        //   description:
        //     "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
        //   color: "#D83B01",
        // },
        // {
        //   title: "Mozilla Campus Captain",
        //   company: "Mozilla",
        //   company_url: "https://www.mozilla.org/",
        //   logo_path: "mozilla_logo.png",
        //   duration: "Oct 2019 - May 2020",
        //   location: "Kurnool, Andhra Pradesh",
        //   description:
        //     "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
        //   color: "#000000",
        // },
        // {
        //   title: "Developer Students Club Member",
        //   company: "DSC IIITDM Kurnool",
        //   company_url:
        //     "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
        //   logo_path: "dsc_logo.png",
        //   duration: "Jan 2018 - May 2020",
        //   location: "Kurnool, Andhra Pradesh",
        //   description:
        //     "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
        //   color: "#0C9D58",
        // },
        {
          title: "Developer Program Member",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "July 2022 - PRESENT",
          location: "Remote",
          description:
            "As a committed member of the GitHub Developer Program, I engage in substantial contributions to a wide array of open-source projects. My involvement spans across notable organizations such as TensorFlow, Uber, Facebook, Google, Scikit-learn, Kiwix, SymPy, Python, NVLabs, FOSSASIA, Netrack, and Keras. My contributions are multifaceted, encompassing bug resolutions, enhancement implementations, and the development of comprehensive documentation to bolster project accessibility and understanding.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects leverage a diverse array of cutting-edge technological tools. My expertise lies in developing responsive web applications and data science projects, and deploying them seamlessly to web platforms using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "the-art-of-consistency",
      name: "The Art of Consistency",
      createdAt: "2024-07-15T00:00:00Z",
      description: "Crafting Masterpieces One Stroke at a Time",
      url:
        "https://medium.com/@peterjamesonsmontana/the-art-of-consistency-crafting-masterpieces-one-stroke-at-a-time-833f1dfc8ffe",
    },
    {
      id: "introduction-to-machine-learning",
      name: "Introduction to Machine Learning",
      createdAt: "2024-07-16T00:00:00Z",
      description: "An Essential Guide to Understanding Machine Learning",
      url:
        "https://medium.com/@peterjamesonsmontana/introduction-to-machine-learning-296cbbf40d1b",
    },
    {
      id: "consistency-models",
      name: "Consistency Models in AI",
      createdAt: "2024-07-12T00:00:00Z",
      description: "A Comprehensive Guide to Consistency Models in AI",
      url:
        "https://medium.com/@peterjamesonsmontana/understanding-consistency-models-in-ai-a-detailed-and-easy-to-comprehend-guide-330ce60fa187",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Let's Connect",
    profile_image_path: "portfolioDPGreen.png",
    description:
      "I'm readily accessible across various social media platforms. Should you wish to reach out, I guarantee a response within 24 hours. My expertise spans Machine Learning, Artificial Intelligence, React, Android Development, Cloud Computing, and Open Source Contributions.",
  },
  blogSection: {
    title: "Insights & Reflections",
    subtitle:
      "I regularly document insights from my professional journey and share knowledge on technical advancements and best practices.",
    link: "https://medium.com/@peterjamesonsmontana",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Kipevu Link, Karen, Nairobi, Kenya",
    locality: "Langata, Karen",
    country: "Kenya",
    region: "Africa",
    postalCode: "00100",
    streetAddress: "Hardy Karen",
    avatar_image_path: "SpideyQRWhatsapp.svg",
    location_map_link: "https://maps.app.goo.gl/ThScjE2R8apMzRpz8?g_st=ac",
  },
  phoneSection: {
    title: "Scan QR Code For Contact",
    subtitle: "QR Too Mysterious? Just Click Me!",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};

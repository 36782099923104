import React from "react";
import "./LoaderLogo.css";
import loaderImage from "../../assests/images/SpideyLoader.svg";

class LogoLoader extends React.Component {
  render() {
    // const theme = this.props.theme;
    return <img src={loaderImage} alt="Intro Logo" />;
  }
}

export default LogoLoader;

import React, { Component } from "react";
import "./FeelingProud.css";
import proudImage from "../../assests/images/portfolioDPGreen.png";
import anotherImage from "../../assests/images/SpideyDpPNG.png"; // Path to the second image

class FeelingProud extends Component {
  state = {
    currentImage: proudImage,
  };

  handleMouseEnter = () => {
    this.setState({ currentImage: anotherImage });
  };

  handleMouseLeave = () => {
    this.setState({ currentImage: proudImage });
  };

  render() {
    return (
      <img
        src={this.state.currentImage}
        alt="Feeling Proud"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        className="spinImage"
      />
    );
  }
}

export default FeelingProud;
